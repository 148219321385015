@font-face {
  font-family: Pokemon;
  src: url(fonts/Pokemon-Solid.ttf);
}

html {
  font-size: calc(62.5% + min(0.2vw, 2px));

  --red: #f24036;
  --yellow: #ffcb01;
  --blue: #2b74ba;
}

body {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;

  color: var(--yellow);
  -webkit-text-stroke-width: 0.3rem;
  -webkit-text-stroke-color: var(--blue);
  text-shadow: 0.5rem 0.3rem 0.2rem rgb(0 0 0 / 30%);
  font-family: Pokemon;
  letter-spacing: 0.5rem;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background: radial-gradient(
      circle at center,
      #1adee6 0,
      #059bdd,
      #1674de,
      #0054b4 100%
    );
  }

  .background-mask {
    position: absolute;
    inset: 0;
    z-index: -1;
    --new-position: top left, center left, bottom right, center right;

    @keyframes slide-1 {
      to {
        background-position: var(--new-position);
        opacity: 0;
        transform: rotate(-40deg);
      }
    }
    @keyframes slide-2 {
      to {
        background-position: var(--new-position);
        opacity: 0;
        transform: rotate(60deg);
      }
    }
    @keyframes slide-3 {
      to {
        background-position: var(--new-position);
        opacity: 0;
        transform: rotate(-60deg);
      }
    }

    background-image: url("./images/poke-ball.png"),
      url("./images/poke-ball.png"), url("./images/poke-ball.png"),
      url("./images/poke-ball.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50px;

    &:first-of-type {
      animation: slide-1 3s linear infinite;
    }
    &:nth-of-type(2) {
      animation: slide-2 3s linear infinite 1s;
    }
    &:nth-of-type(3) {
      animation: slide-3 3s linear infinite 2s;
    }
  }

  #gameContainer {
    position: relative;
    z-index: 0;
    width: 50%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    padding: 4rem 0;
    box-sizing: border-box;

    h1 {
      font-size: 5rem;
      margin: 0;
    }

    #pokemonBox {
      position: relative;
      height: 50%;
      width: 100%;
      background-image: url(images/who-is-that-pokemon-bg.png);
      background-size: cover;
      border-radius: 2rem;
      border: 0.5rem solid var(--yellow);
      background-position: 50% 50%;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      justify-content: center;

      #generationOptions,
      #scoreCounter {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;

        font-size: 2rem;
        border: 0.2rem solid var(--yellow);
        background-color: var(--red);
        padding: 1rem;
        -webkit-text-stroke-width: 0.2rem;
      }
      #generationOptions {
        right: 100%;
        border-radius: 0.5rem 0 0 0.5rem;
        border-right: 0;
        margin: 0;

        li {
          text-transform: uppercase;
          font-size: 1.5rem;
          list-style: none;
          cursor: pointer;

          &.active {
            &::before {
              content: "<";
            }
            &::after {
              content: ">";
            }
          }
        }
      }
      #scoreCounter {
        left: 100%;
        border-radius: 0 0.5rem 0.5rem 0;
        border-left: 0;
      }
      .pokemon-image {
        max-width: 50%;
        max-height: 50%;

        transition: filter 0.4s ease-in;
        transition-delay: 0.4s;
      }
    }

    @keyframes pulse {
      from {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      to {
        transform: scale(1);
      }
    }

    #bottomSection {
      position: relative;
      flex: 1;

      #startGameButton {
        margin: 0 auto;
        background-color: transparent;
        border: none;
        color: var(--yellow);
        font-size: 8rem;
        font-family: Pokemon;
        letter-spacing: 0.8rem;
        cursor: pointer;
        -webkit-text-stroke-width: 0.5rem;
        text-shadow: 0.8rem 0.4rem 0.2rem rgb(0 0 0 / 30%);

        display: none;

        animation: pulse 2s linear infinite;
      }
      ul#guessingOptions,
      #answer {
        position: absolute;
        left: 0;
        width: 100%;
      }
      ul#guessingOptions {
        display: inline-flex;
        gap: 2rem;
        flex-wrap: wrap;

        margin: 0;
        padding: 0;

        transform: scale(0);
        transition: transform 0.4s ease-out;

        li {
          width: calc(50% - 1rem);
          list-style: none;
          text-transform: capitalize;
          font-size: 3rem;
          cursor: pointer;
          transition: transform 0.2s ease-out;

          &:hover {
            transform: scale(1.1);
            transition: transform 0.2s ease-in;
          }
        }
      }

      #answer {
        -webkit-text-stroke-width: 0.5rem;
        font-size: 5rem;
        transition: transform 0.4s ease-out;
        transform: scale(0);
        text-transform: capitalize;
      }
    }

    &.ready {
      #bottomSection #startGameButton {
        display: block;
      }
    }
    &.guessing {
      img.pokemon-image {
        filter: brightness(0);
      }
      #bottomSection #guessingOptions {
        transform: scale(1);
      }
    }
    &.viewing-result {
      #bottomSection #answer {
        transition: transform 0.4s ease-in;
        transition-delay: 0.4s;
        transform: scale(1);
      }
    }
  }
}
