@font-face {
  font-family: Pokemon;
  src: url("Pokemon-Solid.66546939.ttf");
}

html {
  --red: #f24036;
  --yellow: #ffcb01;
  --blue: #2b74ba;
  font-size: calc(62.5% + min(.2vw, 2px));
}

body {
  color: var(--yellow);
  -webkit-text-stroke-width: .3rem;
  -webkit-text-stroke-color: var(--blue);
  text-shadow: .5rem .3rem .2rem #0000004d;
  letter-spacing: .5rem;
  text-align: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  font-family: Pokemon;
  position: fixed;
  overflow: hidden;
}

body:before {
  content: "";
  z-index: -1;
  background: radial-gradient(circle, #1adee6 0, #059bdd, #1674de, #0054b4 100%);
  position: absolute;
  inset: 0;
}

body .background-mask {
  z-index: -1;
  --new-position: top left, center left, bottom right, center right;
  background-image: url("poke-ball.75608416.png"), url("poke-ball.75608416.png"), url("poke-ball.75608416.png"), url("poke-ball.75608416.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50px;
  position: absolute;
  inset: 0;
}

@keyframes slide-1 {
  to {
    background-position: var(--new-position);
    opacity: 0;
    transform: rotate(-40deg);
  }
}

@keyframes slide-2 {
  to {
    background-position: var(--new-position);
    opacity: 0;
    transform: rotate(60deg);
  }
}

@keyframes slide-3 {
  to {
    background-position: var(--new-position);
    opacity: 0;
    transform: rotate(-60deg);
  }
}

body .background-mask:first-of-type {
  animation: 3s linear infinite slide-1;
}

body .background-mask:nth-of-type(2) {
  animation: 3s linear 1s infinite slide-2;
}

body .background-mask:nth-of-type(3) {
  animation: 3s linear 2s infinite slide-3;
}

body #gameContainer {
  z-index: 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  width: 50%;
  height: 100%;
  margin: 0 auto;
  padding: 4rem 0;
  display: flex;
  position: relative;
}

body #gameContainer h1 {
  margin: 0;
  font-size: 5rem;
}

body #gameContainer #pokemonBox {
  border: .5rem solid var(--yellow);
  box-sizing: border-box;
  background-image: url("who-is-that-pokemon-bg.9d2460cc.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  display: flex;
  position: relative;
}

body #gameContainer #pokemonBox #generationOptions, body #gameContainer #pokemonBox #scoreCounter {
  z-index: -1;
  border: .2rem solid var(--yellow);
  background-color: var(--red);
  -webkit-text-stroke-width: .2rem;
  padding: 1rem;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

body #gameContainer #pokemonBox #generationOptions {
  border-right: 0;
  border-radius: .5rem 0 0 .5rem;
  margin: 0;
  right: 100%;
}

body #gameContainer #pokemonBox #generationOptions li {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1.5rem;
  list-style: none;
}

body #gameContainer #pokemonBox #generationOptions li.active:before {
  content: "<";
}

body #gameContainer #pokemonBox #generationOptions li.active:after {
  content: ">";
}

body #gameContainer #pokemonBox #scoreCounter {
  border-left: 0;
  border-radius: 0 .5rem .5rem 0;
  left: 100%;
}

body #gameContainer #pokemonBox .pokemon-image {
  max-width: 50%;
  max-height: 50%;
  transition: filter .4s ease-in .4s;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}

body #gameContainer #bottomSection {
  flex: 1;
  position: relative;
}

body #gameContainer #bottomSection #startGameButton {
  color: var(--yellow);
  letter-spacing: .8rem;
  cursor: pointer;
  -webkit-text-stroke-width: .5rem;
  text-shadow: .8rem .4rem .2rem #0000004d;
  background-color: #0000;
  border: none;
  margin: 0 auto;
  font-family: Pokemon;
  font-size: 8rem;
  animation: 2s linear infinite pulse;
  display: none;
}

body #gameContainer #bottomSection ul#guessingOptions, body #gameContainer #bottomSection #answer {
  width: 100%;
  position: absolute;
  left: 0;
}

body #gameContainer #bottomSection ul#guessingOptions {
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0;
  padding: 0;
  transition: transform .4s ease-out;
  display: inline-flex;
  transform: scale(0);
}

body #gameContainer #bottomSection ul#guessingOptions li {
  text-transform: capitalize;
  cursor: pointer;
  width: calc(50% - 1rem);
  font-size: 3rem;
  list-style: none;
  transition: transform .2s ease-out;
}

body #gameContainer #bottomSection ul#guessingOptions li:hover {
  transition: transform .2s ease-in;
  transform: scale(1.1);
}

body #gameContainer #bottomSection #answer {
  -webkit-text-stroke-width: .5rem;
  text-transform: capitalize;
  font-size: 5rem;
  transition: transform .4s ease-out;
  transform: scale(0);
}

body #gameContainer.ready #bottomSection #startGameButton {
  display: block;
}

body #gameContainer.guessing img.pokemon-image {
  filter: brightness(0);
}

body #gameContainer.guessing #bottomSection #guessingOptions {
  transform: scale(1);
}

body #gameContainer.viewing-result #bottomSection #answer {
  transition: transform .4s ease-in .4s;
  transform: scale(1);
}
/*# sourceMappingURL=index.085568c9.css.map */
